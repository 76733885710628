/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  var $grid = $('.grid').masonry({
      itemSelector: '.grid-item',
      percentPosition: true,
    });

  //reload masory after 500ms
  // place this within dom ready function
  function reloadMasory() {     
    //$grid.masonry('reloadItems');
    $grid.masonry('layout');

  }

 // use setTimeout() to execute
 setTimeout(reloadMasory, 250);


  $('.loadmore-wrapper .goto a.btn-loadmore').on('click',function(){

    var url = $(this).data('url');
    var action = $(this).data('action');
    var offset = $(this).data('offset');

    //$('.load-projects').addClass('active');
    $(this).text('Loading...');

    $.ajax({
      url:url,
      data: {action:action, offset: offset},
      type:'POST', // POST
      dataType: 'JSON',
      beforeSend:function(xhr){
        $('.filter-status').removeClass('hideme').text('We zoeken de beste deals...'); // changing the button label
      },
      success:function(data){
        

        $('.loadmore-wrapper .goto').html(' ');

        $('.loadmore-wrapper .goto').append(data.button);
        
        var $content = $( data.items );
        // add jQuery object
        $grid.append( $content ).masonry( 'appended', $content );
        setTimeout(reloadMasory, 250);

      }
    });
    return false;
  });


  function set_body_class(height)
  {
    if(height  > 1) {
      $('body').addClass('scrolling');
    }else{
      $('body').removeClass('scrolling');
    }
  }
 
  $(window).scroll(function() {
    var height = $(window).scrollTop();
    set_body_class(height);
  });

  var height = $(window).scrollTop();
  set_body_class(height);

  var $menu = $("#hoofdmenu").mmenu({
    "extensions": [
        "position-right"
     ]
    // options
  }, {
    // configuration
    clone: true,
    scrollBugFix:{
      fix:true
    },
    offCanvas: {
      pageSelector: "#page-wrapper"
    }
  });

  var $icon = $("#open-menu");
  var API = $menu.data( "mmenu" );

  $icon.on( "click", function() {
     if($(this).hasClass('is-active')){
      API.close();
     }else{
      API.open();
     }
  });

  API.bind( "open:finish", function() {
     setTimeout(function() {
        $icon.addClass( "is-active" );
     }, 100);
  });
  API.bind( "close:finish", function() {
     setTimeout(function() {
        $icon.removeClass( "is-active" );
     }, 100);
  });



  ////////////// GOOGLE MAPS /////////////////

  /*
  *  center_map
  *
  *  This function will center the map, showing all markers attached to this map
  *
  *  @type  function
  *  @date  8/11/2013
  *  @since 4.3.0
  *
  *  @param map (Google Map object)
  *  @return  n/a
  */

  function center_map( map ) {

    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each( map.markers, function( i, marker ){

      var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

      bounds.extend( latlng );

    });

    // only 1 marker?
    if( map.markers.length === 1 )
    {
      // set center of map
        map.setCenter( bounds.getCenter() );
        map.setZoom( 13 );
    }
    else
    {
      // fit to bounds
      map.fitBounds( bounds );
    }

  }

  /*
  *  add_marker
  *
  *  This function will add a marker to the selected Google Map
  *
  *  @type  function
  *  @date  8/11/2013
  *  @since 4.3.0
  *
  *  @param $marker (jQuery element)
  *  @param map (Google Map object)
  *  @return  n/a
  */

  function add_marker( $marker, map ) {

    // var
    var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

    var wvicon = $('.acf-map').data('marker');

    // create marker
    var marker = new google.maps.Marker({
      position  : latlng,
      map       : map,
      icon      : wvicon
    });

    // add to array
    map.markers.push( marker );

    // if marker contains HTML, add it to an infoWindow
    if( $marker.html() )
    {
      // create info window
      var infowindow = new google.maps.InfoWindow({
        content   : $marker.html()
      });

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function() {

        infowindow.open( map, marker );

      });
    }

  }

  function new_map( $el ) {
    
    // var
    var $markers = $el.find('.marker');
    
    
    // vars
    var args = {
      zoom    : 13,
      center    : new google.maps.LatLng(0, 0),
      mapTypeId : google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: false,
      styles : [    //https://snazzymaps.com/style/15/subtle-grayscale
          {
              "featureType": "administrative",
              "elementType": "all",
              "stylers": [
                  {
                      "saturation": "-100"
                  }
              ]
          },
          {
              "featureType": "administrative.province",
              "elementType": "all",
              "stylers": [
                  {
                      "visibility": "off"
                  }
              ]
          },
          {
              "featureType": "landscape",
              "elementType": "all",
              "stylers": [
                  {
                      "saturation": -100
                  },
                  {
                      "lightness": 65
                  },
                  {
                      "visibility": "on"
                  }
              ]
          },
          {
              "featureType": "poi",
              "elementType": "all",
              "stylers": [
                  {
                      "saturation": -100
                  },
                  {
                      "lightness": "50"
                  },
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "road",
              "elementType": "all",
              "stylers": [
                  {
                      "saturation": "-100"
                  }
              ]
          },
          {
              "featureType": "road.highway",
              "elementType": "all",
              "stylers": [
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "road.arterial",
              "elementType": "all",
              "stylers": [
                  {
                      "lightness": "30"
                  }
              ]
          },
          {
              "featureType": "road.local",
              "elementType": "all",
              "stylers": [
                  {
                      "lightness": "40"
                  }
              ]
          },
          {
              "featureType": "transit",
              "elementType": "all",
              "stylers": [
                  {
                      "saturation": -100
                  },
                  {
                      "visibility": "simplified"
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "geometry",
              "stylers": [
                  {
                      "hue": "#ffff00"
                  },
                  {
                      "lightness": -25
                  },
                  {
                      "saturation": -97
                  }
              ]
          },
          {
              "featureType": "water",
              "elementType": "labels",
              "stylers": [
                  {
                      "lightness": -25
                  },
                  {
                      "saturation": -100
                  }
              ]
          }
      ] 
    };
    
    
    // create map           
    var map = new google.maps.Map( $el[0], args);
    
    
    // add a markers reference
    map.markers = [];
    
    
    // add markers
    $markers.each(function(){
      
        add_marker( $(this), map );
      
    });
    
    
    // center map
    center_map( map );
    
    
    // return
    return map;
    
  }

  // global var
  var map = null;

  $('.acf-map').each(function(){

    // create map
    map = new_map( $(this) );

  });

  ////////////// END GOOGLE MAPS /////////////////











  

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
